import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';
import SimilarCard from '../../components/similar.card';
import CtaSection from '../../components/cta.section';

import heroImg from '../../assets/img/services/website.jpg';
import iconChrono from '../../assets/img/icons/chrono.svg';
import iconMedal from '../../assets/img/icons/medal.svg';
import iconStar from '../../assets/img/icons/bookmark.svg';
import iconFlash from '../../assets/img/icons/flash.svg';
import iconEqualizer from '../../assets/img/icons/equalizer.svg';
import iconTools from '../../assets/img/icons/tools.svg';
import iconSpeedo from '../../assets/img/icons/speedometer.svg';

import similar1 from '../../assets/img/services/thumbnail/site-vitrine.jpg';
import similar2 from '../../assets/img/services/thumbnail/site-e-commerce.jpg';
import similar3 from '../../assets/img/services/thumbnail/intranet.jpg';
import cta1 from '../../assets/img/illustrations/questions.svg';

import SEO from '../../components/seo';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const boxStyle = {
  backgroundImage: 'url(' + heroImg + ')'
};

export default () => (
  <div id="top">
    <SEO
      title="Création et refonte de sites internet | Agence digitale | MDS Digital Agency"
      description="MDS est spécialisé dans la création et refonte de sites internet : création de site vitrine, plateforme de vente en ligne et intranet/extranet."
      keywords="création et refonte de sites internet"
    />
    <Menu />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={heroImg} />
    <article className={pageStyle.article}>
      <h2>Création et refonte de sites internet & e-commerce</h2>
      <p>Vous souhaitez créer un site web ou effectuer une refonte de votre site actuel ?</p>
      <p>
        MDS est spécialisé dans la création et refonte de sites internet. Qu’il s’agisse d’un{' '}
        <AniLink cover bg="#fba515" to="/services/website/site-vitrine/">
          site vitrine
        </AniLink>{' '}
        pour votre entreprise, d’une plateforme de{' '}
        <AniLink cover bg="#fba515" to="/services/website/site-e-commerce/">
          vente en ligne
        </AniLink>{' '}
        ou du développement d’un{' '}
        <AniLink cover bg="#fba515" to="/services/website/site-intranet-extranet/">
          intranet/extranet
        </AniLink>
        , MDS vous accompagne depuis l'idée initiale de votre projet jusqu'à sa réalisation
        complète.
      </p>

      <div className={pageStyle.box_container}>
        <div style={boxStyle} className={pageStyle.box_panel_left}>
          <h3>Pourquoi faire appel à MDS pour créer votre site internet ?</h3>
        </div>
        <div className={pageStyle.box_panel_right}>
          <div className={pageStyle.box_content}>
            <ul>
              <li>
                <img className={pageStyle.icon} src={iconChrono} alt="icone cible" />
                <span className={pageStyle.box_list_text}>Analyse pointue de votre projet</span>
              </li>
              <li>
                <img className={pageStyle.icon} src={iconEqualizer} alt="icone cible" />
                <span className={pageStyle.box_list_text}>Approche personnalisée</span>
              </li>
              <li>
                <img className={pageStyle.icon} src={iconMedal} alt="icone cible" />
                <span className={pageStyle.box_list_text}>
                  Utilisation des dernières technologies
                </span>
              </li>
              <li>
                <img className={pageStyle.icon} src={iconFlash} alt="icone cible" />
                <span className={pageStyle.box_list_text}>
                  Véritable stratégie de{' '}
                  <AniLink cover bg="#fba515" to="/services/mise-a-jour-contenu/">
                    contenu
                  </AniLink>
                  et d’aide au positionnement
                </span>
              </li>
              <li>
                <img className={pageStyle.icon} src={iconStar} alt="icone cible" />
                <span className={pageStyle.box_list_text}>
                  <AniLink cover bg="#fba515" to="/services/referencement/">
                    Référencement
                  </AniLink>
                  naturel optimal
                </span>
              </li>
              <li>
                <img className={pageStyle.icon} src={iconTools} alt="icone cible" />
                <span className={pageStyle.box_list_text}>
                  Prise en charge de l’hébergement et de la maintenance technique de votre site
                </span>
              </li>
              <li>
                <img className={pageStyle.icon} src={iconSpeedo} alt="icone cible" />
                <span className={pageStyle.box_list_text}>
                  Rapidité et proactivité d’une équipe pluridisciplinaire
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <CtaSection
        title="Combien ça coûte ? Combien de temps prendra l'élaboration de mon site ?"
        subtitle="Ne restez plus sans réponse"
        ctaText="Demandez un devis en ligne"
        img={cta1}
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Site vitrine" url="/services/website/site-vitrine/" img={similar1} />
          <SimilarCard
            text="Site e-commerce"
            url="/services/website/site-e-commerce/"
            img={similar2}
          />
          <SimilarCard
            text="Site intranet/extranet"
            url="/services/website/site-intranet-extranet/"
            img={similar3}
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
